import {
  and,
  collection,
  collectionData,
  CollectionReference,
  doc,
  docData,
  documentId,
  DocumentReference,
  Firestore,
  getCountFromServer,
  or,
  query,
  where,
} from '@angular/fire/firestore';
import { CurrentUser } from '../current-user/get-current-user.service';
import { combineLatest, from, Observable, of, switchMap, tap } from 'rxjs';
import { UserTypes } from '../users/user.types';
import { filter, map } from 'rxjs/operators';
import { PersonalInfo } from './personal-info';
import { chunk } from 'lodash';
import { inputIsNotNullOrUndefined } from "./input-is-not-null-or-undefined";

export interface UserRelation {
  id: string,
  userId: string,
  accountId: string,
  reportToId: string | string[] | null,
  createdAt: number,
  updatedAt: number,
  updatedById: string,
  type?: UserTypes,
}

export interface UserWithInfo extends UserRelation, PersonalInfo {
}

/**
 * Owner/Admin can see all users
 * Lead can see only users reports to this lead including himself
 * @param firestore
 * @param currentUser
 */
export const getRelatedUsers$ = (firestore: Firestore, currentUser: CurrentUser): Observable<UserRelation[]> => {
  const usersQueryConstraint = currentUser.type === UserTypes.OWNER
    ? and(where('accountId', '==', currentUser.accountId))
    : or(
      where('reportToId', '==', currentUser.id),
      where('reportToId', 'array-contains', currentUser.id),
    );
  const usersQuery = query(
    collection(firestore, 'users') as CollectionReference<UserRelation>,
    usersQueryConstraint
  );

  return combineLatest([
    currentUser.type && [ UserTypes.OWNER, UserTypes.LEAD, UserTypes.INSIDE_SALES ].includes(currentUser.type)
      ? collectionData(usersQuery, { idField: 'id' }) : of(undefined),
    currentUser.type !== UserTypes.OWNER ?
      docData(doc(firestore, 'users', currentUser.id), { idField: 'id' }) :
      of(undefined),
  ]).pipe(
    map(([ users, currentUser ]) =>
      ([ ...(users || []), ...(currentUser ? [ currentUser ] : []) ] as UserRelation[])
    ),
  );
};

/**
 *
 * @param firestore
 * @param currentUser
 */
export const getRelatedUserIds$ = (firestore: Firestore, currentUser: CurrentUser): Observable<string[]> => {
  return getRelatedUsers$(firestore, currentUser).pipe(
    map(userRelations => userRelations.map(userRelation => userRelation.id))
  );
};

/**
 *
 * @param firestore
 * @param uid - Auth user ID
 */
export const getCurrentUser$ = (firestore: Firestore, uid: string): Observable<CurrentUser> => {
  const userDoc = doc(firestore, `users/${ uid }`) as DocumentReference<UserRelation>;
  const personalInfoDoc = doc(firestore, `personalInfo/${ uid }`) as DocumentReference<PersonalInfo>;
  return combineLatest([
    docData<UserRelation>(userDoc, { idField: 'id' }).pipe(filter(inputIsNotNullOrUndefined)),
    docData<PersonalInfo>(personalInfoDoc, { idField: 'id' }).pipe(filter(inputIsNotNullOrUndefined)),
  ]).pipe(map(([ userRelation, personalInfo ]) => ({
    ...userRelation,
    ...personalInfo,
  })));
};

export const getCompanyUsers$ = (firestore: Firestore, currentUser: CurrentUser): Observable<UserRelation[]> => {
  const usersQueryConstraint = currentUser.type === UserTypes.OWNER
    ? and(where('accountId', '==', currentUser.accountId))
    : or(
      where('reportToId', '==', currentUser.id),
      where('reportToId', 'array-contains', currentUser.id),
    );
  const usersQuery = query(
    collection(firestore, 'users') as CollectionReference<UserRelation>,
    usersQueryConstraint
  );

  return combineLatest([
    ...(currentUser.type !== UserTypes.OWNER ? [
      docData<UserRelation>(doc(firestore, `users/${ currentUser.id }`) as DocumentReference<UserRelation>, {
        idField: 'id',
      }).pipe(filter(inputIsNotNullOrUndefined))
    ] : []),
    collectionData<UserRelation>(usersQuery, { idField: 'id' }),
  ]).pipe(
    map(users => users.flat()),
    switchMap(users =>
      from(getCountFromServer(usersQuery)).pipe(
        filter(usersCountResponse =>
          currentUser.type === UserTypes.OWNER
            ? users.length === usersCountResponse.data().count
            : users.length === usersCountResponse.data().count + 1
        ),
        map(() => users),
      )
    ),
  );
};

export const getUsersByIds$ = (firestore: Firestore, ids: string[]): Observable<UserRelation[]> => {
  return combineLatest(chunk(ids, 10).map(idsBatch => {
    return collectionData<UserRelation>(
      query(
        collection(firestore, 'users') as CollectionReference<UserRelation>,
        where(documentId(), 'in', idsBatch)
      ), {
        idField: 'id',
      }
    );
  })).pipe(
    map(usersGroup => usersGroup.flat()),
  );
};

export const withInfoForUsers$ = (firestore: Firestore, users: UserRelation[]): Observable<UserWithInfo[]> => {
  return combineLatest(chunk(users, 10).map(usersBatch => {
    return collectionData<PersonalInfo>(
      query(
        collection(firestore, 'personalInfo') as CollectionReference<PersonalInfo>,
        where(documentId(), 'in', usersBatch.map(user => user.id))
      ), {
        idField: 'id',
      },
    ).pipe(
      map(personalInfoList => personalInfoList.map(personalInfo => {
        const relatedUser = usersBatch.find(user => user.id === personalInfo.id);
        if (!relatedUser) throw new Error('Unexpected: Related user is not found');
        return {
          ...relatedUser,
          ...personalInfo,
        };
      })),
    );
  })).pipe(
    map(usersGroup => usersGroup.flat()),
    filter(usersWithInfo => usersWithInfo.length === users.length),
  );
};

export const getAllCompanyUsers$ = (firestore: Firestore, companyId: string): Observable<UserRelation[]> => {
  const usersRef = collection(firestore, 'users') as CollectionReference<UserRelation>;
  return collectionData<UserRelation>(query(usersRef, where('accountId', '==', companyId)), {
    idField: 'id',
  });
};
